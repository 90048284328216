import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "./ConsentDetails.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import cx from "classnames";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import $ from "jquery";

import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { findIndex } from "lodash";
import PdfViewer from "./PdfViewer";
import LoadingButton from "@mui/lab/LoadingButton";
import FormModal from "./Fp17Form/DentalTreatmentModal";
import ReactPlayer from "react-player";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useRef } from "react";
import ImageGallery from "./ImageGallery/ImageGallery";

const steps = [
  "Consent Details",
  "Treatment Identified",
  "Consent form",
  "Videos",
];

const customStyleMap = {
  "rdw-center-aligned-block": {
    textAlign: "center",
  },
};

export default function ConsentDetails() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  let { consentBundleId } = useParams();
  const [treatmentOptions, setTreatmentOptions] = React.useState<any[]>([]);
  const [selectedPlans, setSelectedPlans] = React.useState<any[]>([]);
  const [value, setValue] = React.useState(1);
  const [activeStep, setActiveStep] = React.useState(0);
  const [editorState, setEditorState] = React.useState<any>(
    EditorState.createEmpty()
  );
  const [data, setData] = React.useState<any>(null);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [isOpenPdfViewer, setIsOpenPdfViewer] = React.useState(false);
  const [isLoadingViewPdf, setIsLoadingViewPdf] = React.useState(false);
  const [isUpdatingPrice, setIsUpdatingPrice] = React.useState(false);
  const [isRequirefp17dc, setIsRequirefp17dc] = React.useState(false);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  const [practitionerList, setPractitionerList] = useState([]);
  const [isLoadingPractitioners, setIsLoadingPractitioners] = useState(true);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);

  const user = queryClient.getQueryData(["user"]);

  //video
  const [selectedVideo, setSelectedVideo] = React.useState<any>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);
  //const [selectedVideoIndex, setSelectedVideoIndex] = React.useState<any>(0);
  const videoRef = useRef(null);
  const editorRef = useRef(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [content, setContent] = useState("");
  const [instances, setInstances] = useState([]);
  const editorInstanceRef = useRef(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    // Load the CKEditor script dynamically
    const script = document.createElement("script");
    script.src = "/ckeditor/ckeditor.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("CKEditor script loaded.");
      setIsScriptLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const initializeEditor = () => {
      if (editorRef.current && window.CKEDITOR) {
        const editorInstance = window.CKEDITOR.replace(editorRef.current, {
          height: 800,
        });

        if (editorInstance) {
          editorInstance.on("instanceReady", () => {
            console.log("CKEditor instance ready.");
            editorInstance.setData(content);

            editorInstance.on("change", () => {
              const updatedContent = editorInstance.getData();
              setContent(updatedContent);
            });
          });

          editorInstanceRef.current = editorInstance;
        } else {
          console.error("Failed to initialize CKEditor.");
        }
      }
    };

    const initializeEditorclean = () => {
      const x = window.CKEDITOR.instances;
      const instanceKeys = Object.keys(x);

      if (Object.keys(x).length > 0) {
        console.log("Destroying existing CKEditor instance...");
        instanceKeys.forEach((key) => {
          window.CKEDITOR.instances[key].destroy(true);
          console.log(`${key} instance destroyed.`);
        });
      } else {
        console.log(true);
      }
    };

    if (activeStep === 1) {
      initializeEditorclean();
    }
    if (activeStep === 3) {
      initializeEditorclean();
    }

    if (refreshed) {
      console.log("in refershed");
      initializeEditorclean();
    }

    if (isScriptLoaded && isDataLoaded && activeStep === 2) {
      initializeEditor();
    }

    return () => {
      if (editorRef.current && window.CKEDITOR) {
        const existingInstance =
          window.CKEDITOR.instances[editorRef.current.id];
        if (existingInstance) {
          existingInstance.destroy();
          delete window.CKEDITOR.instances[editorRef.current.id];
        }
      }
    };
  }, [isScriptLoaded, isDataLoaded, activeStep, content, refreshed]);

  const treatmentDetails = useQuery(
    ["treatmentDetails"],
    async () => {
      const { data } = await axios.get(`/consent_bundle/${consentBundleId}`);
      setIsRequirefp17dc(data.require_fp17dc);

      return data;
    },
    {
      onSuccess: async (data) => {
        setData(data);
        if (data.status !== "Draft") {
          navigate(`/${data.id}/summary`);
        }

        const { data: practitionerData } = await axios.get(
          `/consent_bundle/${data.id}/get_practitioner_list_for_consent_bundle`
        );
        setPractitionerList(practitionerData);

        if (data?.clinician_id && practitionerData?.length > 0) {
          const matchedPractitioner = practitionerData.find(
            (practitioner) => practitioner.dentally_id === data.clinician_id
          );
          if (matchedPractitioner) {
            setSelectedPractitioner(matchedPractitioner.id); // Auto-select the practitioner
          }
        }

        const txt = data?.consent_form?.body || "";
        setContent(txt);
        setIsDataLoaded(true);

        const plans = data.treatment_plan.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
        }));

        setSelectedPlans(plans);

        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },

      enabled: !!consentBundleId,
      refetchOnWindowFocus: false,
      staleTime: 480000,
    }
  );

  const previewPdf = async () => {
    const consentForm = editorInstanceRef.current.getData();
    setIsLoadingViewPdf(true);

    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(consentForm, "text/html");
      const imgTags = Array.from(doc.querySelectorAll("img"));
      let updatedConsentForm = doc.body.innerHTML;

      for (const img of imgTags) {
        const src = img.src;
        const imgAlt = img.alt || "";
        const imgHeight = img.getAttribute("height");
        const imgWidth = img.getAttribute("width");

        if (
          !img.style ||
          !img.style.cssText.includes("height") ||
          !img.style.cssText.includes("width")
        ) {
          const imgStyle = `height: ${imgHeight || "auto"}px; width: ${
            imgWidth || "auto"
          }px;`;
          const correctedImgTag = `<img src="${src}" alt="${imgAlt}" style="${imgStyle}" />`;
          updatedConsentForm = updatedConsentForm.replace(
            img.outerHTML,
            correctedImgTag
          );
        }

        if (src.startsWith("data:image")) {
          const mimeTypeMatch = src.match(/data:(image\/[a-zA-Z]+);base64,/);
          if (mimeTypeMatch) {
            const mimeType = mimeTypeMatch[1];
            const base64Data = src.replace(/data:image\/[a-zA-Z]+;base64,/, "");
            const byteString = atob(base64Data);
            const arrayBuffer = new Uint8Array(byteString.length);

            for (let i = 0; i < byteString.length; i++) {
              arrayBuffer[i] = byteString.charCodeAt(i);
            }

            const file = new File([arrayBuffer], "image.png", {
              type: mimeType,
            });

            // console.log("file",file)
            // const imgGallery = await handleImageUpload(file);

            const response = await uploadCallback(file);
            const newImgTag = `<img src="${response.data.link}" alt="${imgAlt}" style="height: 300px; width: 300px;" />`;
            updatedConsentForm = updatedConsentForm.replace(
              img.outerHTML,
              newImgTag
            );
          }
        }
      }

      editorInstanceRef.current.setData(updatedConsentForm);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/generate_preview_pdf`,
        method: "POST",
        responseType: "blob",
        data: { consentForm: updatedConsentForm },
      });

      console.log(updatedConsentForm);
      const href = URL.createObjectURL(response.data);
      setPdfUrl(href);
      setIsOpenPdfViewer(true);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsLoadingViewPdf(false);
    }
  };

  // Upload Callback
  const uploadCallback = (file) => {
    //console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`/consent_clause/upload_image_to_s3`, formData)
      .then((response) => ({ data: { link: response.data } }))
      .catch((err) => Promise.reject(err));
  };



  // const handleImageUpload = async (file) => {
  //   try {
     
  //    // console.log(file)
  //     const token = axios.defaults.headers.common.Authorization;
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     await axios.post("consent_clause/upload_user_media_to_s3", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: token,
  //       },
  //     });
  //   } catch (err) {

  //     console.error(err); 
  //   }
  // };
  

 


  const handlePractitionerChange = (e) => {
    const selectedId = e.target.value;
    setSelectedPractitioner(selectedId);

    if (selectedId) {
      toast.success(
        `Saved practitioner: ${e.target.options[e.target.selectedIndex].text}`
      );
    }
  };
  React.useEffect(() => {
    if (data && data.playlists && data.playlists.length > 0) {
      const playListIndex = findIndex(data.playlists, { status: true });
      const playlist = data?.playlists?.[playListIndex]?.playlist_items || [];
      const currentVideo = playlist?.[currentVideoIndex] || null;
      setSelectedVideo(currentVideo);
    }
  }, [data, currentVideoIndex]);

  React.useEffect(() => {
    setPageLoaded(true);
  }, []);

  const handleVideoEnd = () => {
    playNextVideo();
  };

  const playNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => prevIndex + 1);
  };

  const addNewTreatment = useMutation(
    (treatmentId) => {
      return axios.put(
        `/consent_bundle/${treatmentDetails.data.id}/add_treatment/${treatmentId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },
    }
  );

  const deleteTreatment = useMutation(
    (treatmentId: number) => {
      return axios.delete(
        `/consent_bundle/${treatmentDetails.data.id}/remove_treatment/${treatmentId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },
    }
  );

  const refreshTreatments = useMutation(
    () => {
      return axios.patch(
        `/consent_bundle/${treatmentDetails.data.id}/refresh_treatment_items`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },
    }
  );

  const deleteVideo = useMutation(
    (videoId: number) => {
      return axios.put(
        `/consent_bundle/${treatmentDetails.data.id}/toggle_visibility_playlist_item/${videoId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
      },
    }
  );

  const deletePlaylist = useMutation(
    (id: number) => {
      return axios.put(
        `/consent_bundle/${treatmentDetails.data.id}/toggle_visibility_playlist/${id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
      },
    }
  );

  const sendForSigning = useMutation(
    (consentForm: any) => {
      return axios.post(
        `/consent_bundle/${treatmentDetails.data.id}/send_consent_bundle_for_signing`,
        { consentForm }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        window.location.href = `/${consentBundleId}/thankyou`;
      },
    }
  );

  const logData = useMutation((data: any) => {
    return axios.post(`/log`, data);
  });

  const sendForSigningFunction = () => {
    const consentForm = editorInstanceRef.current.getData();
    // const markup = draftToHtml(rawContentState);
    sendForSigning.mutate(consentForm);
  };

  const refreshPricing = () => {
    setIsUpdatingPrice(true);
    setRefreshed(false);
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/refresh_treatment_pricing`
      )
      .then(() => {
        setIsUpdatingPrice(false);
        // queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        toast.success("Treatment Pricing Updated");
      })
      .catch(() => {
        setIsUpdatingPrice(false);
        toast.error("Failed to update treatment pricing");
      });
  };

  const treatmentPlans = useQuery(
    ["treatmentPlans"],
    async () => {
      const { data } = await axios.get(
        `/treatment?practice_id=${treatmentDetails.data.practice_id}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        const plans = data.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
        }));
        setTreatmentOptions(plans);
      },
      enabled: !!treatmentDetails?.data?.practice_id,
      refetchOnWindowFocus: false,
      staleTime: 480000,
    }
  );

  const handleDelete = (plan) => {
    deleteTreatment.mutate(plan.treatment_id);
  };

  const handleSelectPlan = (newValue: any) => {
    addNewTreatment.mutate(newValue.id);
  };

  const deleteVideoFunction = (videoId) => {
    deleteVideo.mutate(videoId);
  };

  const deletePlaylistFunction = (id) => {
    deletePlaylist.mutate(id);
  };
  const [clickedVideoId, setClickedVideoId] = React.useState(null);
  const hackUpdate = (video) => {
    setClickedVideoId(video.id);
    setSelectedVideo(null);
    setTimeout(() => {
      setSelectedVideo(video);
    }, 0);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = async () => {
    try {
      const response = await axios.get(`/consent_bundle/${consentBundleId}`);

      if (response.status === 200) {
        setFormData(response.data.fp17dc_data);
        setSelectedTeeth(response.data.fp17dc_data);
      }
      setOpen(true);
    } catch (error) {
      console.error("Error fetching consent bundle data:", error);
    }
  };

  const [showAddButton, setShowAddButton] = useState(false);
  const [fp17dcIsChecked, setFp17dcIsChecked] = useState(false);

  const handleCheckboxChangetoggle = async (event) => {
    const isChecked = event.target.checked;
    setFp17dcIsChecked(isChecked);

    setFormData({ ...formData, checkbox: isChecked });

    try {
      const response = await axios.put(
        `/consent_bundle/${consentBundleId}/toggle_fp17dc_form_generation`
      );

      setShowAddButton(response.data);
      await treatmentDetails.refetch();
    } catch (error) {
      console.error("Error updating FP17DC form generation:", error);
    }
  };

  const handleSave = async () => {
    try {
      const updatedFormData = {
        ...formData,
      };

      const response = await axios.post(
        `/consent_bundle/${consentBundleId}/save_fp17dc_form_data`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = response.data;

      toast.success("Fp17DC data saved successfully");

      if (isRequirefp17dc) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          checkbox: true,
        }));
      }

      setOpen(false);
    } catch (error) {
      console.error("Error saving form data:", error);
      toast.error(`Failed to save form data: ${error.message}`);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleClickModalOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    if (isRequirefp17dc) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        checkbox: true,
      }));
    }
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalConfirm = () => {
    sendForSigningFunction();
    handleClose();
  };

  useEffect(() => {
    if (isRequirefp17dc) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        checkbox: true,
      }));
    }
  }, [isRequirefp17dc]);

  const handleInputChange = (event) => {
    const { name, type, value, checked } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked ? "Yes" : "No") : value,
    }));
  };

  const [formData, setFormData] = React.useState({});

  const [selectedTeeth, setSelectedTeeth] = useState([]);

  const handleToothSelect = (tooth) => {
    setSelectedTeeth((prevSelectedTeeth) => {
      const newSelectedTeeth =
        prevSelectedTeeth[tooth] === "Yes"
          ? { ...prevSelectedTeeth, [tooth]: null }
          : { ...prevSelectedTeeth, [tooth]: "Yes" };

      setFormData((prevFormData) => ({
        ...prevFormData,
        [tooth]: newSelectedTeeth[tooth],
      }));

      return newSelectedTeeth;
    });
  };

  return (
    <div className={styles.consentContainer}>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Consent Details</h2>
            {treatmentDetails?.data?.patient && (
              <div className={styles.column}>
                <p className={styles.dataItem}>
                  Name: {treatmentDetails.data.patient.title}{" "}
                  {treatmentDetails.data.patient.first_name}{" "}
                  {treatmentDetails.data.patient.last_name}
                </p>
                <p className={styles.subDataItem}>
                  Email: {treatmentDetails.data.patient.email_address}
                </p>
                <p className={styles.subDataItem}>
                  Mobile: {treatmentDetails.data.patient.mobile_phone}
                </p>
                <p className={styles.subDataItem}>
                  Phone: {treatmentDetails.data.patient.home_phone}
                </p>
                <p className={styles.subDataItem}>
                  Address: {treatmentDetails.data.patient.address_line_1}{" "}
                  {treatmentDetails.data.patient.address_line_2}
                </p>
                <p className={styles.subDataItem}>
                  NHS Number: {treatmentDetails.data.patient.nhs_number}
                </p>
                <p className={styles.subDataItem}>
                  Version: {treatmentDetails.data.version_no}
                </p>

                <select
                  id="practitioner"
                  name="practitioner"
                  className={styles.dropdown}
                  onChange={handlePractitionerChange}
                  value={selectedPractitioner}
                >
                  {practitionerList && practitionerList.length > 0 ? (
                    practitionerList.map((practitioner) => (
                      <option key={practitioner.id} value={practitioner.id}>
                        {practitioner.name}
                      </option>
                    ))
                  ) : (
                    <option value="">No practitioners available</option>
                  )}
                </select>
              </div>
            )}
          </div>

          <div className={styles.footer}>
            <Button variant="outlined" color="secondary" csize="large" disabled>
              Back
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={async () => {
                if (!selectedPractitioner) {
                  alert("Please select a practitioner.");
                  return;
                }

                try {
                  await axios.put(
                    `/consent_bundle/${consentBundleId}/set_practitioner_for_consent_bundle/${selectedPractitioner}`
                  );
                  setActiveStep(1);
                  await logData.mutateAsync({
                    type: "info",
                    description:
                      "Click next button to move from Consent Details to Treatment Identified",
                    event: "Module Access",
                    data: {
                      consentBundleId: consentBundleId,
                    },
                    practice_id: user?.medicube_practice_id,
                    user_id: user?.medicube_user_id,
                  });
                } catch (error) {
                  console.error("Error setting practitioner:", error);
                  alert("Failed to set the practitioner. Please try again.");
                }
              }}
              disabled={!pageLoaded || !selectedPractitioner}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {activeStep === 1 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Treatment Identified</h2>
            <div className={styles.treatmentSection}>
              <Autocomplete
                value={null}
                disableClearable
                onChange={(event: any, newValue: any) => {
                  handleSelectPlan(newValue);
                }}
                options={treatmentOptions}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search other treatments"
                    variant="filled"
                  />
                )}
              />
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => refreshTreatments.mutate()}
              >
                Refresh
              </Button>
              {(refreshTreatments.isLoading ||
                deleteTreatment.isLoading ||
                addNewTreatment.isLoading ||
                treatmentDetails.isLoading ||
                treatmentPlans.isLoading) && <CircularProgress />}
            </div>
            <div className={styles.treatmentPlans}>
              {selectedPlans.map((plan, index) => {
                return (
                  <Chip
                    key={index}
                    label={plan.label}
                    variant="outlined"
                    onDelete={() => handleDelete(plan)}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setActiveStep(0);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click back button to move from Treatment Identified to Consent Details",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setActiveStep(2);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click next button to move from Treatment Identified to Videos",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
              disabled={!pageLoaded}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {activeStep === 2 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Consent form</h2>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
           
           {/* testxt area */}
            <div style={{ textAlign: "right" }}>
              <button
                type="button"
                onClick={() => setShowGallery(!showGallery)}
                style={{
                  backgroundColor: "#ff9500",
                  color: "black",
                  padding: "8px 12px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showGallery ? "Close Gallery" : "Open Gallery"}
              </button>
            </div>

           
            <div style={{ display: "flex", flex: 1 }}>
             
              <div style={{ flex: 4, minWidth: "500px" }}>
                <textarea
                  ref={editorRef}
                  style={{
                    width: "100%",
                    height: "400px",
                    resize: "horizontal",
                    overflow: "auto",
                    whiteSpace: "pre-wrap",
                  }}
                />
              </div>

              {showGallery && (
                <div
                  style={{
                    flex: 1,
                    minWidth: "200px",
                    maxWidth: "30%",
                    transition: "max-width 0.3s",
                    overflow: "hidden",
                  }}
                >
                  <div className={styles.imageGalleryContainer}>
                    <ImageGallery
                      showGallery={showGallery}
                      onClose={() => setShowGallery(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.column}>
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <label>
                  <input
                    type="checkbox"
                    checked={formData.checkbox}
                    onChange={handleCheckboxChangetoggle}
                  />
                  Enable FP17DC
                </label>
                &nbsp;
                {showAddButton || isRequirefp17dc ? (
                  <button
                    onClick={handleOpen}
                    style={{
                      backgroundColor: "darkblue",
                      color: "white",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Edit FP17DC
                  </button>
                ) : null}
              </Box>

              <FormModal
                open={open}
                handleClose={handleClose}
                formData={formData}
                handleInputChange={handleInputChange}
                handleSave={handleSave}
                handleCheckboxChange={handleCheckboxChangetoggle}
                handleToothSelect={handleToothSelect}
                selectedTeeth={selectedTeeth}
              />
            </div>
          </div>

          <div className={styles.footer}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setActiveStep(1);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click back button to move from Videos to Treatment Identified",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
            >
              Back
            </Button>

            <div
              className={styles.buttonGroup}
              style={{ display: "flex", gap: "16px" }}
            >
              <LoadingButton
                loading={isLoadingViewPdf}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={previewPdf}
              >
                Preview
              </LoadingButton>
              <LoadingButton
                loading={isUpdatingPrice}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={refreshPricing}
              >
                Refresh Treatment Pricing
              </LoadingButton>
            </div>

            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setActiveStep(3);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click next button to move from Videos to Consent form",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
              disabled={!pageLoaded}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {activeStep === 3 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Videos</h2>
          </div>
          {treatmentDetails?.data?.playlists?.length > 0 ? (
            <div className={styles.videos}>
              <div className={styles.playListContainer}>
                <TabContext value={value}>
                  <div className="tab-container">
                    <TabList
                      onChange={(e, v) => {
                        setValue(v);
                        logData.mutateAsync({
                          type: "info",
                          description: "Click video playlist",
                          event: "Module Access",
                          data: {
                            consentBundleId: consentBundleId,
                            playlistId:
                              treatmentDetails.data.playlists[v - 1].id,
                          },
                          practice_id: user?.medicube_practice_id,
                          user_id: user?.medicube_user_id,
                        });
                      }}
                      variant="scrollable"
                      className="custom-tab-list"
                    >
                      {treatmentDetails?.data?.playlists?.map((list, index) => {
                        if (deletePlaylist.isLoading) {
                          return (
                            <Tab
                              disabled={deletePlaylist.isLoading}
                              key={index}
                              label={list?.title}
                              value={index + 1}
                            />
                          );
                        } else if (list.status) {
                          return (
                            <Tab
                              key={index}
                              disabled={deletePlaylist.isLoading}
                              label={list?.title}
                              value={index + 1}
                              icon={
                                <VisibilityIcon
                                  onClick={() =>
                                    deletePlaylistFunction(list.id)
                                  }
                                />
                              }
                            />
                          );
                        } else {
                          return (
                            <Tab
                              key={index}
                              disabled={deletePlaylist.isLoading}
                              label={list?.title}
                              value={index + 1}
                              icon={
                                <VisibilityOffIcon
                                  onClick={() =>
                                    deletePlaylistFunction(list.id)
                                  }
                                />
                              }
                            />
                          );
                        }
                      })}
                    </TabList>
                  </div>
                  {treatmentDetails?.data?.playlists?.map(
                    (playlist, playlistsIndex) => {
                      return (
                        <TabPanel
                          className={styles.playList}
                          value={playlistsIndex + 1}
                        >
                          {playlist?.playlist_items?.map((video, index) => {
                            return (
                              <div className={styles.video} key={index}>
                                <Chip
                                  className={cx(styles.description, {
                                    [styles.active]:
                                      (index === currentVideoIndex &&
                                        !clickedVideoId) ||
                                      video.id === clickedVideoId,
                                  })}
                                  label={video.title}
                                  avatar={
                                    <img
                                      className={styles.thumbnail}
                                      src={video.thumbnail}
                                    />
                                  }
                                  variant={video.status ? "outlined" : "filled"}
                                  onDelete={() => deleteVideoFunction(video.id)}
                                  deleteIcon={
                                    video.status ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )
                                  }
                                  onClick={() => {
                                    hackUpdate(video);
                                    logData.mutateAsync({
                                      type: "info",
                                      description: "Click video thumbnail",
                                      event: "Module Access",
                                      data: {
                                        consentBundleId: consentBundleId,
                                        video: video.title,
                                      },
                                      practice_id: user?.medicube_practice_id,
                                      user_id: user?.medicube_user_id,
                                    });
                                  }}
                                  disabled={deleteVideo.isLoading}
                                />
                              </div>
                            );
                          })}
                        </TabPanel>
                      );
                    }
                  )}
                </TabContext>
              </div>
              <div className={styles.player}>
                {selectedVideo ? (
                  <ReactPlayer
                    url={selectedVideo.url}
                    playing={false} // Disables autoplay
                    controls
                    width="100%"
                    height="480px"
                    onEnded={handleVideoEnd}
                  />
                ) : (
                  <p className={styles.noVideos}>No active videos to display</p>
                )}
              </div>
            </div>
          ) : (
            <p className={styles.noVideos}>
              There are no videos for you to watch; please click 'Send' to
              submit the consent form.{" "}
            </p>
          )}

          <div className={styles.footer}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setActiveStep(2);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click back button to move from Consent form to Videos",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
              disabled={sendForSigning.isLoading}
            >
              Back
            </Button>

            {treatmentDetails.data.version_no > 1 ? (
              <LoadingButton
                loading={sendForSigning.isLoading}
                variant="contained"
                color="success"
                size="large"
                onClick={handleClickModalOpen}
                disabled={sendForSigning.isLoading}
              >
                Send
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={sendForSigning.isLoading}
                variant="contained"
                color="success"
                size="large"
                onClick={sendForSigningFunction}
                disabled={sendForSigning.isLoading}
              >
                Send
              </LoadingButton>
            )}
          </div>

          <Dialog
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your patient will lose access to the previous version. Would you
                like to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose} color="primary">
                No
              </Button>
              <Button onClick={handleModalConfirm} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {pdfUrl && isOpenPdfViewer && (
        <PdfViewer
          isOpen={isOpenPdfViewer}
          close={() => setIsOpenPdfViewer(false)}
          pdfUrl={pdfUrl}
        />
      )}
    </div>
  );
}
